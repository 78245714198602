import React, { useState, useEffect } from 'react';
import StoreList from '../components/StoreList';
import { Row, Col, Form, Button, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getGvsGenerated } from '../actions/VoucherAction';
import { createStoreGvs, clearGvsList } from '../actions/VoucherAction';
import * as XLSX from 'xlsx';

function CreateStoreGiftVoucher() {
  const [gvDeno, setgvDeno] = useState(0);
  const [gvNos, setGvNos] = useState(0);
  const [validfrom, setValidFrom] = useState('');
  const [validto, setValidTo] = useState('');
  const [storeId, setStoreId] = useState('');
  const [storePrefix, setStorePrefix] = useState('');
  const [isStorePrefixError, setIsStorePrefixError] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const gvcreateReducer = useSelector(state => state.gvstoCreate);
  const { error, loading, createdvouchers } = gvcreateReducer;

  const dispatch = useDispatch();
  const { gvslist } = useSelector((state) => state.gvsGenereated);
  
    const handleStoreChange = (storecode) => {

       setStorePrefix(storecode.prefix);
       setStoreId(storecode.id);

    }

const generateVoucher = (e) => {
    e.preventDefault(); 
    
   
    if (!storePrefix) {
        setIsStorePrefixError(true);
        return; 
    } else {
        setIsStorePrefixError(false); 
        dispatch(getGvsGenerated(gvNos)); 
    }
};

const handleSubmitStoreGvs = () => {
    if (!storePrefix || gvslist.length === 0) {
      setIsStorePrefixError(true);
      return; 
    }

    const vouchersToCreate = gvslist.map((gv, index) => ({
        storeid: storeId, 
        voucherno: `${storePrefix}${gv.id}`, 
        voucheramt: gvDeno, 
        validfrom: validfrom,
        validto: validto,
        voucherstatus: 0, 
    }));

    
    dispatch(createStoreGvs(vouchersToCreate));

    const ws = XLSX.utils.json_to_sheet(gvslist.map(gv => ({
        "Voucher Code": `${storePrefix}${gv.id}`,
        "Denomination": gvDeno,
    })));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Vouchers");
    XLSX.writeFile(wb, "store-vouchers.xlsx");

    setShowSuccessMessage(true); // Show success message
    setTimeout(() => setShowSuccessMessage(false), 5000);

    

  };

  useEffect(() => {
    if (showSuccessMessage) {
       
        setTimeout(() => {
            setgvDeno(0); 
            setGvNos(0); 
            setStorePrefix(''); 
            setStoreId('');
            dispatch(clearGvsList()); 
        }, 1000); 
    }
}, [showSuccessMessage, dispatch]);
  return (
    <div>
      <h3>Create Store Gift Vouchers</h3>
      <hr />
      <StoreList onStoreChange={handleStoreChange} />
      {isStorePrefixError && <p className="text-danger">Please select a store</p>}

      <hr />
      {showSuccessMessage && <div className="alert alert-success" role="alert">
    Vouchers created successfully!
</div>}

      <Form>
        <Row className="align-items-center mb-3">
        <Col>
            <Form.Group controlId="validdatefrom">
              <Form.Label>Valid From</Form.Label>
              <Form.Control
                type="date"
                name="validfrom"
                value={validfrom}
                onChange={(e) => setValidFrom(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="validdateto">
              <Form.Label>Valid To</Form.Label>
              <Form.Control
                type="date"
                name="validto"
                value={validto}
                onChange={(e) => setValidTo(e.target.value)}
                required
              />
            </Form.Group>
          </Col>


          <Col md={4}>
            <Form.Group controlId="gvAmount">
              <Form.Label>GV Denomination</Form.Label>
              <Form.Control
                type="number"
                value={gvDeno}
                onChange={(e) => setgvDeno(parseInt(e.target.value, 10))}
              />
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group controlId="gvnos">
              <Form.Label>No. of Gift Vouchers</Form.Label>
              <Form.Control
                type="number"
                value={gvNos}
                onChange={(e) => setGvNos(parseInt(e.target.value, 10))}
              />
            </Form.Group>
          </Col>

          <Col md={4}>
            <Button variant="primary" onClick={generateVoucher} className="mt-3">
              Add
            </Button>
          </Col>

          <Col md={4}>
          <Button onClick={handleSubmitStoreGvs} variant="success" className="my-3">
              Create Store GVs
            </Button>
          </Col>
          
        </Row>
      </Form>

      {gvslist  && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Voucher Code</th>
              <th>Denomination</th>
            </tr>
          </thead>
          <tbody>
            {gvslist.map((gv, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{`${storePrefix}${gv.id}`}</td>
                <td>{gvDeno}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default CreateStoreGiftVoucher;
