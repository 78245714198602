import './index.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import React, {useState} from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import HomeScreen from './screens/HomeScreen';
import CreateGVMap from './screens/CreateGVMap';
import CreateStoreGiftVoucher from './screens/CreateStoreGiftVoucher';
import StoreGVList from './screens/StoreGVList';
import MappedVouchers from './screens/MappedVouchersList'
import { MappedVoucherList } from './reducers/VoucherReducer';
import LoginScreen from './screens/LoginScreen';
import RedeemedVouchers from './screens/VouchersRedeemed'
import ProtectedRoute from './components/ProtectedRoute'

function App() {


  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem('isLoggedIn') === 'true' || false
  );

  const handleLogin = () => {
    // Perform login logic here and set isLoggedIn to true if login is successful
    setIsLoggedIn(true);
    // Store the login state in localStorage
    localStorage.setItem('isLoggedIn', 'true');
  };

  const handleLogout = () => {
    // Perform logout logic here and set isLoggedIn to false
    setIsLoggedIn(false);
    // Clear the stored login state in localStorage
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('userInfo');
   
  
  };


  return (
    <Router>

{isLoggedIn && <Header onLogout={handleLogout} isLoggedIn = {isLoggedIn} />}
      
      <main className='py-3'>
        <Container>
          <Routes>


          {isLoggedIn ? (
            // If logged in, allow access to protected routes
            <Route path="/"/>
          ) : (
            // If not logged in, redirect to the login screen
            <Route
              path="/"
              element={
                <LoginScreen
                  onLogin={handleLogin}
                  onLogout={handleLogout}
                  isLoggedIn={isLoggedIn}
                />
              }
            />
          )}
            {/* Use `element` to specify the component. */}
            <Route path='/' element={<ProtectedRoute><HomeScreen /></ProtectedRoute>} exact />
            <Route path='/mapgiftvoucher' element={<ProtectedRoute><CreateGVMap /></ProtectedRoute>}  />
            <Route path='/createstoregv' element={<ProtectedRoute><CreateStoreGiftVoucher /></ProtectedRoute>}  />
            <Route path='/storegvlist' element={<ProtectedRoute><StoreGVList /></ProtectedRoute>}  />
            <Route path='/mappedvoucherlist' element={<ProtectedRoute><MappedVouchers /></ProtectedRoute>}  />
            <Route path='/vouchersredeemed' element={<ProtectedRoute><RedeemedVouchers /></ProtectedRoute>}  />

          </Routes>
        </Container>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
