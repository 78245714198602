import './bootstrap.min.css'
import React from 'react';
import { Provider } from 'react-redux'
import store from './store'
import ReactDOM from 'react-dom/client';
import App from './App';


const el = document.getElementById('root');
const root = ReactDOM.createRoot(el);


root.render (
<Provider store={store}>
    <App/>
    </Provider>


)