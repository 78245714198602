import { createStore, combineReducers  } from 'redux'
import { applyMiddleware } from 'redux';
import {thunk} from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension'
import { voucherListReducer, generatedGvList,
     createStoreGV, 
     storeGvsList, 
     gvmapCreate, 
     MappedVoucherList, 
     voucherCheckReduer, GVRedeemList } from './reducers/VoucherReducer'
import { storeListReducer } from './reducers/StoresReducer'
import { productSearchReducer, productListReducer } from './reducers/ProductReducer'
import { userloginReducer } from './reducers/UserReducer'

const reducer = combineReducers({

    lisofVourchers : voucherListReducer,
    listofProducts : productListReducer,
    productSearch : productSearchReducer, 
    listofStores: storeListReducer, 
    gvsGenereated: generatedGvList,
    gvstoCreate: createStoreGV,
    storeGvsList : storeGvsList, 
    mappedVouchers : gvmapCreate,
    lisofMappedVouchers: MappedVoucherList,
    userlogin : userloginReducer,
    checkVoucher : voucherCheckReduer,
    gvredeemlist : GVRedeemList
    

})

const intitalState = {}

const middleware = [thunk]

const store = createStore(reducer, intitalState, 
composeWithDevTools(applyMiddleware(...middleware)))

export default store
