import React, { useState, useEffect } from 'react';
import StoreList from '../components/StoreList';
import { Row, Col, Form, Button, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { storegvsGenerated } from '../actions/VoucherAction';

function StoreGVList() {
    const dispatch = useDispatch();
    const { storegvlist } = useSelector((state) => state.storeGvsList);
    

    const handleStoreChange = (storecode) => {

      
        dispatch(storegvsGenerated(storecode.id))
      
    }
 
    
  return (
    <div>
      <h3>Price Match GV List</h3>
      <hr />
      <StoreList onStoreChange={handleStoreChange} />      

      {storegvlist  && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Voucher Code</th>
              <th>Denomination</th>
              <th>Status</th>

            </tr>
          </thead>
          <tbody>
            {storegvlist.map((gv, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{gv.voucherno}</td>
                <td>{gv.voucheramt}</td>
                <td>{gv.voucherstatus}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

    </div>
  )
}

export default StoreGVList
