import { 
    
    VOUCHER_LIST_REQUEST,
    VOUCHER_LIST_SUCCESS,
    VOUCHER_LIST_FAIL,

    VOUCHER_CHECK_REQUEST,
    VOUCHER_CHECK_SUCCESS,
    VOUCHER_CHECK_FAIL,

    STORE_GV_GENERATE_REQUEST,
    STORE_GV_GENERATE_SUCCESS,
    STORE_GV_GENERATE_FAIL,

    STORE_GV_CREATE_REQUEST,
    STORE_GV_CREATE_SUCCESS,
    STORE_GV_CREATE_FAIL,
    CLEAR_GVS_LIST,

    STORE_GV_LIST_REQUEST,
    STORE_GV_LIST_SUCCESS,
    STORE_GV_LIST_FAIL, 

    GV_MAP_CREATE_REQUEST,
    GV_MAP_CREATE_SUCCESS,
    GV_MAP_CREATE_FAIL, 

    GV_MAPPED_LIST_REQUEST,
    GV_MAPPED_LIST_SUCCESS,
    GV_MAPPED_LIST_FAIL,

    GV_REDEEMED_LIST_REQUEST,
    GV_REDEEMED_LIST_SUCCESS,
    GV_REDEEMED_LIST_FAIL


} from '../constants/VoucherConstant'


export const GVRedeemList = (state = {redeemedvouchers: []}, action) => {

    switch(action.type){

        case GV_REDEEMED_LIST_REQUEST:
            return {...state, loading: true, redeemedvouchers: []}
        
        case GV_REDEEMED_LIST_SUCCESS:
            return {...state, loading:false, redeemedvouchers: action.payload}
        
        case GV_REDEEMED_LIST_FAIL:
            return {loading: false, error: action.payload}          
            
        default:
            return state

    }


}

export const MappedVoucherList = (state = {mappedvouchers: []}, action) => {

    switch(action.type){

        case GV_MAPPED_LIST_REQUEST:
            return {...state, loading: true, mappedvouchers: []}
        
        case GV_MAPPED_LIST_SUCCESS:
            return {...state, loading:false, mappedvouchers: action.payload}
        
        case GV_MAPPED_LIST_FAIL:
            return {loading: false, error: action.payload}          
            
        default:
            return state

    }


}

export const storeGvsList = (state = {storegvlist: []}, action) => {

    switch(action.type){

        case STORE_GV_LIST_REQUEST:
            return {...state, loading: true, storegvlist: []}
        
        case STORE_GV_LIST_SUCCESS:
            return {...state, loading:false, storegvlist: action.payload}
        
        case STORE_GV_LIST_FAIL:
            return {loading: false, error: action.payload}          
            
        default:
            return state

    }


}

export const gvmapCreate = (state = {mappedGvs: []}, action) => {

    switch(action.type){

        case GV_MAP_CREATE_REQUEST:
            return {...state, loading: true, mappedGvs: []}
        
        case GV_MAP_CREATE_SUCCESS:
            return {...state, loading:false, mappedGvs: action.payload}
        
        case GV_MAP_CREATE_FAIL:
            return {loading: false, error: action.payload}
          
            
        default:
            return state

    }


}

export const createStoreGV = (state = {createdvouchers: []}, action) => {

    switch(action.type){

        case STORE_GV_CREATE_REQUEST:
            return {...state, loading: true, createdvouchers: []}
        
        case STORE_GV_CREATE_SUCCESS:
            return {...state, loading:false, createdvouchers: action.payload}
        
        case STORE_GV_CREATE_FAIL:
            return {loading: false, error: action.payload}
        
     
            
        default:
            return state

    }


}

export const generatedGvList = (state = {gvslist: []}, action) => {

    switch(action.type){

        case STORE_GV_GENERATE_REQUEST:
            return {...state, loading: true, gvslist: []}
        
        case STORE_GV_GENERATE_SUCCESS:
            return {...state, loading:false, gvslist: action.payload}
        
        case STORE_GV_GENERATE_FAIL:
            return {loading: false, error: action.payload}
        
        case CLEAR_GVS_LIST:
                return {
                    ...state,
                    gvslist: [], // Reset gvslist to an empty array
                };
        
        default:
            return state

    }


}

export const voucherListReducer = (state = {vouchers: []}, action) => {

    switch(action.type){

        case VOUCHER_LIST_REQUEST:
            return {loading: true, vouchers: []}
        
        case VOUCHER_LIST_SUCCESS:
            return {loading:false, vouchers: action.payload}
        
        case VOUCHER_LIST_FAIL:
            return {loading: false, error: action.payload}
        
        
        default:
            return state

    }


}

export const voucherCheckReduer = (state = {checkresult: {}}, action) => {

    switch(action.type){

        case VOUCHER_CHECK_REQUEST:
            return {loading: true, checkresult: {}}
        
        case VOUCHER_CHECK_SUCCESS:
            return {loading:false, checkresult: action.payload}
        
        case VOUCHER_CHECK_FAIL:
            return {loading: false, error: action.payload}
        
        
        default:
            return state

    }


}
