import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Dropdown, FormGroup } from 'react-bootstrap';
import { getVoucherTypes } from '../actions/VoucherAction';

import '../styles/listofvouchers.css';

function VouchersList({ onVoucherTypeChange }) {
  const dispatch = useDispatch();
  const voucherList = useSelector((state) => state.lisofVourchers);

  const { vouchers } = voucherList;

  const [selectedVoucherType, setSelectedVoucherType] = useState(""); // Use an empty string for initialization

  useEffect(() => {
    dispatch(getVoucherTypes());
  }, [dispatch]);

  const dropdownStyle = {
    width: '30%',
    backgroundColor: 'transparent', // Set background to transparent
    color: 'black', // Set text color to black for visibility
    borderColor: '#ced4da', // Optionally set the border color
  };

  const handleVoucherChange = (voucher) => {
    setSelectedVoucherType(voucher.voucher_type); // Assuming you want to display the voucher type
    onVoucherTypeChange(voucher);
  };

  return (
    <Form.Group>
      <Dropdown>
        <Dropdown.Toggle variant="" id="vouchertypedropdown" style={dropdownStyle}>
          {selectedVoucherType || 'Select a Promo Type'}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {vouchers.map((voucher) => (
            <Dropdown.Item
              key={voucher.id}
              onClick={() => handleVoucherChange(voucher)}
            >
              {voucher.voucher_type}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Form.Group>
  );
}

export default VouchersList;
