import axios from 'axios'
import { 
    
    STORE_LIST_REQUEST,
    STORE_LIST_SUCCESS,
    STORE_LIST_FAIL

} from '../constants/StoreConstant'
const baseUrl = process.env.REACT_APP_API_BASE_URL;
export const getStoreList = () => async (dispatch) => {

    try
    {
       
        dispatch({type: STORE_LIST_REQUEST})

        const config = {

            headers: {

                'Content-type': 'application/json',
                'api-key-header': '4df12f3c-33b9-4f77-bf4c-f96dbbbb1fa8'
            }
        }
      
          const response = await axios.get(`${baseUrl}/storelist`, {
            config, 
          });
      
        dispatch({ 

            type: STORE_LIST_SUCCESS,
            payload : response.data
        })

    } catch (error) {

        dispatch({

            type: STORE_LIST_FAIL, 
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,

        })
    
    }

}