import axios from 'axios'
import { 
    
    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_LIST_FAIL,

    PRODUCT_SEARCH_REQUEST,
    PRODUCT_SEARCH_SUCCESS,
    PRODUCT_SEARCH_FAIL,


} from '../constants/ProductConstant'
const baseUrl = process.env.REACT_APP_API_BASE_URL;
export const getProduct = (eancode, fromdate, todate) => async (dispatch) => {
    try {
        const response = await axios.get(`${baseUrl}/getItem/${eancode}/${fromdate}/${todate}`);
        const data = response.data;
        dispatch({ type: PRODUCT_SEARCH_SUCCESS, payload: data });
        return data; 
      
    } catch (error) {
        dispatch({ type: PRODUCT_SEARCH_FAIL, payload: error.toString() });
        return Promise.reject(error);
    }

    
};

export const getProductList = () => async (dispatch) => {

    try
    {
       
        dispatch({type: PRODUCT_LIST_REQUEST})

        const config = {

            headers: {

                'Content-type': 'application/json',
                'api-key-header': '4df12f3c-33b9-4f77-bf4c-f96dbbbb1fa8'
            }
        }
      
          const response = await axios.get(`${baseUrl} /productlist`, {
            config, 
          });
      
        dispatch({ 

            type: PRODUCT_LIST_SUCCESS,
            payload : response.data
        })

    } catch (error) {

        dispatch({

            type: PRODUCT_LIST_FAIL, 
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,

        })
    
    }

}