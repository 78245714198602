import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { mappedvoucheraction } from '../actions/VoucherAction';
import * as XLSX from 'xlsx';

function MappedVouchersList() {
    const dispatch = useDispatch();
    const { mappedvouchers } = useSelector((state) => state.lisofMappedVouchers);
    const [filter, setFilter] = useState('');
    const [brandFilter, setBrandFilter] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    useEffect(() => {
        dispatch(mappedvoucheraction());
    }, [dispatch]);

    useEffect(() => {
        console.log(mappedvouchers);
    }, [mappedvouchers]);

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const handleBrandFilterChange = (e) => {
        setBrandFilter(e.target.value);
    };

    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    const filteredVouchers = mappedvouchers.filter(voucher => {
        const validFrom = new Date(voucher.validfrom);
        const validTo = new Date(voucher.validto);
        const fromDateCondition = fromDate ? new Date(fromDate) <= validFrom : true;
        const toDateCondition = toDate ? new Date(toDate) >= validTo : true;
        const dateRangeCondition = fromDateCondition && toDateCondition;

        return (
            (voucher.gvcaption.toLowerCase().includes(filter.toLowerCase()) ||
            voucher.sku.toLowerCase().includes(filter.toLowerCase())) &&
            voucher.brand.toLowerCase().includes(brandFilter.toLowerCase()) &&
            dateRangeCondition
        );
    });

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(filteredVouchers);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Vouchers");
        XLSX.writeFile(workbook, "MappedVouchers.xlsx");
    };

    return (
        <div>
            <h3>Mapped GV Promotions</h3>
            <Row>
                <Form.Label>Filter by Promo Title, EAN Code, Brand, or Date Range</Form.Label>
                <Col md={3}>
                    <Form.Group controlId="formGridFilter">
                        <Form.Control
                            type="text"
                            placeholder="Enter promo title or EAN code"
                            value={filter}
                            onChange={handleFilterChange}
                        />
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group controlId="formGridBrandFilter">
                        <Form.Control
                            type="text"
                            placeholder="Enter brand"
                            value={brandFilter}
                            onChange={handleBrandFilterChange}
                        />
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group controlId="formGridFromDate">
                        <Form.Control
                            type="date"
                            value={fromDate}
                            onChange={handleFromDateChange}
                        />
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group controlId="formGridToDate">
                        <Form.Control
                            type="date"
                            value={toDate}
                            onChange={handleToDateChange}
                        />
                    </Form.Group>
                </Col>
                <Col md={12} className="text-right">
                    <Button variant="success" onClick={exportToExcel}>Export Data</Button>
                </Col>
            </Row>
            <hr />
            {mappedvouchers && (
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Promo ID</th>
                            <th>Promo Type</th>
                            <th>Promo Title</th>
                            <th>Valid From</th>
                            <th>Valid To</th>
                            <th>Cap Qty</th>
                            <th>Ean Code</th>
                            <th>Description</th>
                            <th>Brand</th>
                            <th>GV Amount</th>
                            <th>GV Int Amount</th>
                            <th>GV Ext Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredVouchers.map((gv, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{gv.id}</td>
                                <td>{gv.promo_type}</td>
                                <td>{gv.gvcaption}</td>
                                <td>{gv.validfrom}</td>
                                <td>{gv.validto}</td>
                                <td>{gv.capqty}</td>
                                <td>{gv.sku}</td>
                                <td>{gv.description}</td>
                                <td>{gv.brand}</td>
                                <td>{gv.gvamount}</td>
                                <td>{gv.gvintamount}</td>
                                <td>{gv.gvextamount}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </div>
    );
}

export default MappedVouchersList;
