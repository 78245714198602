import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Dropdown, FormGroup } from 'react-bootstrap';
import { getStoreList, getVoucherTypes } from '../actions/StoreAction';

function StoreList({ onStoreChange }) {
    const dispatch = useDispatch();
    const storeList = useSelector((state) => state.listofStores);
  
    const { stores } = storeList;
  
    const [selectdStore, setSelectedStore] = useState(""); // Use an empty string for initialization
  
    useEffect(() => {
      dispatch(getStoreList());
    }, [dispatch]);
  
    const dropdownStyle = {
      width: '30%',
      backgroundColor: 'transparent', // Set background to transparent
      color: 'black', // Set text color to black for visibility
      borderColor: '#ced4da', // Optionally set the border color
    };
  
    const handleStoreChange = (store) => {
      setSelectedStore(store.storename); // Assuming you want to display the voucher type
      onStoreChange(store);
    };
  
    return (
      <Form.Group>
        <Dropdown>
          <Dropdown.Toggle variant="" id="storedropdown" style={dropdownStyle}>
            {selectdStore || 'Select a Store'}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {stores.map((store) => (
              <Dropdown.Item
                key={store.id}
                onClick={() => handleStoreChange(store)}
              >
                {store.storename}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Form.Group>
    );
}

export default StoreList
