export const PRODUCT_CREATE_REQUEST = 'PRODUCT_CREATE_REQUEST'
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS'
export const PRODUCT_CREATE_FAIL = 'PRODUCT_CREATE_FAIL'

export const PRODUCT_LIST_REQUEST = 'PRODUCT_CREATE_REQUEST'
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_CREATE_SUCCESS'
export const PRODUCT_LIST_FAIL = 'PRODUCT_CREATE_FAIL'

export const PRODUCT_SEARCH_REQUEST = 'PRODUCT_SEARCH_REQUEST'
export const PRODUCT_SEARCH_SUCCESS = 'PRODUCT_SEARCH_SUCCESS'
export const PRODUCT_SEARCH_FAIL = 'PRODUCT_SEARCH_FAIL'
