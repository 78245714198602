import axios from 'axios'
import { 
    
    VOUCHER_LIST_REQUEST,
    VOUCHER_LIST_SUCCESS,
    VOUCHER_LIST_FAIL,

    STORE_GV_GENERATE_REQUEST,
    STORE_GV_GENERATE_SUCCESS,
    STORE_GV_GENERATE_FAIL,

    STORE_GV_CREATE_REQUEST,
    STORE_GV_CREATE_SUCCESS,
    STORE_GV_CREATE_FAIL,    
    CLEAR_GVS_LIST,

    STORE_GV_LIST_REQUEST,
    STORE_GV_LIST_SUCCESS,
    STORE_GV_LIST_FAIL, 

    GV_MAP_CREATE_REQUEST,
    GV_MAP_CREATE_SUCCESS,
    GV_MAP_CREATE_FAIL,

    GV_MAPPED_LIST_REQUEST,
    GV_MAPPED_LIST_SUCCESS,
    GV_MAPPED_LIST_FAIL,

    VOUCHER_CHECK_REQUEST,
    VOUCHER_CHECK_SUCCESS,
    VOUCHER_CHECK_FAIL, 

    GV_REDEEMED_LIST_REQUEST,
    GV_REDEEMED_LIST_SUCCESS,
    GV_REDEEMED_LIST_FAIL


} from '../constants/VoucherConstant'

const baseUrl = process.env.REACT_APP_API_BASE_URL;
export const checkgvItem = (itemid, startdate, enddate) => async (dispatch) => {
    try {
        dispatch({type: VOUCHER_CHECK_REQUEST});

        const config = {
            headers: {
                'Content-type': 'application/json',
            },
        };

        const {data} = await axios.get(`${baseUrl}/check-gv-item?gv_item_code=${itemid}&valid_from=${startdate}&valid_to=${enddate}`, {}, config);

        dispatch({
            type: VOUCHER_CHECK_SUCCESS,
            payload: data,
        });
       
        console.log("voucher success data", data)
    } catch (error) {
        dispatch({
            type: VOUCHER_CHECK_FAIL,
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail : error.message,
        });
    }
};


export const clearGvsList = () => {
    return {
        type: CLEAR_GVS_LIST,
    };
};


export const gvredeemlistaction = (startdate, enddate) => async (dispatch) => {
    try {
        dispatch({ type: GV_REDEEMED_LIST_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const response = await axios.get(`${baseUrl}/gvredeemrecords/?fromdate=${startdate}&todate=${enddate}`, config);

        dispatch({
            type: GV_REDEEMED_LIST_SUCCESS,
            payload: response.data            
        });

    } catch (error) {
        dispatch({
            type: GV_REDEEMED_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const mappedvoucheraction = () => async (dispatch) => {

    try
    {
       
        dispatch({type: GV_MAPPED_LIST_REQUEST})

        const config = {

            headers: {

                'Content-type': 'application/json',
            }
        }
      
          const response = await axios.get(`${baseUrl}/mappedgvresult/`, {
            config, 
          });
      
        dispatch({ 

            type: GV_MAPPED_LIST_SUCCESS,
            payload : response.data
        })

    } catch (error) {

        dispatch({

            type: GV_MAPPED_LIST_FAIL, 
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,

        })
    
    }

}

export const createMappedGvs = (request) => async (dispatch) => {
    try {
        dispatch({type: GV_MAP_CREATE_REQUEST});

        const config = {
            headers: {
                'Content-type': 'application/json',
            },
        };

        const {data} = await axios.post(`${baseUrl}/gvheaders/`, request, config);

        dispatch({
            type: GV_MAP_CREATE_SUCCESS,
            payload: data,
        });

        console.log(data);
       
    } catch (error) {
        dispatch({
            type: GV_MAP_CREATE_FAIL,
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail : error.message,
        });
    }
};

export const storegvsGenerated = (storeid) => async (dispatch) => {
    try {
        dispatch({type: STORE_GV_LIST_REQUEST});

        const config = {
            headers: {
                'Content-type': 'application/json',
            },
        };

        const {data} = await axios.post(`${baseUrl}/getStoreVouchers/${storeid}`, {}, config);

        dispatch({
            type: STORE_GV_LIST_SUCCESS,
            payload: data,
        });
       
    } catch (error) {
        dispatch({
            type: STORE_GV_LIST_FAIL,
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail : error.message,
        });
    }
};


export const createStoreGvs = (request) => async (dispatch) => {
    try {
        dispatch({type: STORE_GV_CREATE_REQUEST});

        const config = {
            headers: {
                'Content-type': 'application/json',
            },
        };

        // Corrected to include gvnos in the query and added an empty object {} as the second argument
        const {data} = await axios.post(`${baseUrl}/store-vouchers/`, request, config);

        dispatch({
            type: STORE_GV_CREATE_SUCCESS,
            payload: data,
        });

     
       
    } catch (error) {
        dispatch({
            type: STORE_GV_CREATE_FAIL,
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail : error.message,
        });
    }
};

export const getGvsGenerated = (gvnos) => async (dispatch) => {
    try {
        dispatch({type: STORE_GV_GENERATE_REQUEST});

        const config = {
            headers: {
                'Content-type': 'application/json',
            },
        };

        // Corrected to include gvnos in the query and added an empty object {} as the second argument
        const {data} = await axios.post(`${baseUrl}/generate-unique-numbers?qty=${gvnos}`, {}, config);

        dispatch({
            type: STORE_GV_GENERATE_SUCCESS,
            payload: data,
        });

     
       
    } catch (error) {
        dispatch({
            type: STORE_GV_GENERATE_FAIL,
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail : error.message,
        });
    }
};

export const getVoucherTypes = () => async (dispatch) => {

    try
    {
       
        dispatch({type: VOUCHER_LIST_REQUEST})

        const config = {

            headers: {

                'Content-type': 'application/json',
                'api-key-header': '4df12f3c-33b9-4f77-bf4c-f96dbbbb1fa8'
            }
        }
      
          const response = await axios.get(`${baseUrl}/getvoucherlist`, {
            config, 
          });
      
        dispatch({ 

            type: VOUCHER_LIST_SUCCESS,
            payload : response.data
        })

    } catch (error) {

        dispatch({

            type: VOUCHER_LIST_FAIL, 
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,

        })
    
    }

}
