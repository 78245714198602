import axios from 'axios'
import { 
    
 
    USER_LOGOUT, 

    
    USER_LOGIN_REQUEST, 
    USER_LOGIN_SUCCESS, 
    USER_LOGIN_FAIL,

 

   
} from '../constants/UserConstant'
const baseUrl = process.env.REACT_APP_API_BASE_URL;
export const login =  (emailid, password) => async (dispatch) => {


    try{

        dispatch({type: USER_LOGIN_REQUEST})

        const config = {

            headers: {

                'Content-type': 'application/json'
            }
        }
       
        const {data} = await axios.post('https://vktech.app/login', 
        {"email_address" : emailid,   "password": password },
        config
        )

        
        dispatch({

            type: USER_LOGIN_SUCCESS,
            payload: data
            
        })

        localStorage.setItem('userInfo', JSON.stringify(data))
       

    } catch(error) {

        dispatch ({

            type: USER_LOGIN_FAIL,
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail : error.message

        })


    }


}
