import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Table, Alert, Modal } from 'react-bootstrap';
import VouchersList from '../components/VouchersList';
import { useDispatch, useSelector } from 'react-redux';
import { getProduct } from '../actions/ProductAction';
import { createMappedGvs, checkgvItem } from '../actions/VoucherAction'
import Papa from 'papaparse';

import {
  PRODUCT_SEARCH_SUCCESS,
  PRODUCT_SEARCH_FAIL,
} from '../constants/ProductConstant';

function CreateGVMap() {
  const dispatch = useDispatch();
  const { productInfo } = useSelector((state) => state.productSearch);
  const { productcheck } = useSelector((state) => state.checkVoucher);

  const [eancode, setEanCode] = useState('');
  const [caption, setCaption] = useState('');
  const [datefrom, setDateFrom] = useState('');
  const [dateto, setDateTo] = useState('');
  const [gvamount, setGvAmount] = useState('');
  const [gvstat, setGvStat] = useState('');
  const [gvinvamount, setGvInvAmount] = useState('');
  const [gvextamount, setGvExtAmount] = useState('');
  const [capqty, setCapQty] = useState('');
  const [gvrefno, setGVRefno] = useState('');
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [itemid, setItemId] = useState('');
  const [voucherId, setvoucherId] = useState('');
  const [gvstatus, setGvStatus] = useState('');
  const [gvitemid, setGvItemId] = useState('');
  const [vouchers, setVouchers] = useState([]);
  const [errors, setErrors] = useState([]); 

  const [showModal, setShowModal] = useState(false);
  const [modalErrors, setModalErrors] = useState([]);


  useEffect(() => {
    if (datefrom && dateto) {
      if (window.confirm("Changing the dates will clear all existing vouchers. Do you want to proceed?")) {
        setVouchers([]);
      }
    }
  }, [datefrom, dateto]);


  useEffect(() => {
    if (productInfo) {
      console.log("THIS IS THE GV INFO", productInfo)
      setProductName(productInfo.item_name || '');
      setProductDescription(productInfo.description || '');
      setItemId(productInfo.id || '');
      setGvItemId(productInfo.item_id || '');
      setGvStatus(productInfo.gvstatus || '');
    }
  }, [productInfo]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'eancode':
        setEanCode(value);
        break;
      case 'caption':
        setCaption(value);
        break;
      case 'datefrom':
        setDateFrom(value);
        break;
      case 'dateto':
        setDateTo(value);
        break;
      case 'gvamount':
        setGvAmount(value);
        break;
      case 'gvinvamount':
        setGvInvAmount(value);
        break;
      case 'gvextamount':
        setGvExtAmount(value);
        break;
      case 'capqty':
          setCapQty(value);
          break;
      case 'gvrefno':
          setGVRefno(value);
          break;
      case 'gvstatus':
            setGvStatus(value);
            break;
  
          
        default:
        break;
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && e.target.name === 'eancode') {
      e.preventDefault(); 

      if (!datefrom || !dateto) {
        alert('Please fill in both the "Valid From" and "Valid To" dates before proceeding.');
        return; // Exit the function if either date is missing
      }

      dispatch(getProduct(eancode, datefrom, dateto))
      .then((action) => {
        if (action.type === PRODUCT_SEARCH_SUCCESS) {
          if (productInfo) {
            setProductName(productInfo.item_name || '');
            setProductDescription(productInfo.description || '');
            setItemId(productInfo.id || '');
            setGvItemId(productInfo.item_id || '');
            setGvStatus(productInfo.gvstatus || '');
          }
        } else if (action.type === PRODUCT_SEARCH_FAIL) {
            alert('Failed to find product. Please check the EAN code and try again.');
            setEanCode('');
        }
    })
    .catch((error) => {
        console.error('An unexpected error occurred: ', error);
    });
      
    }
  };

  const addVoucher = () => {

    const isEanCodeExist = vouchers.some(voucher => voucher.eancode === eancode);

    if (isEanCodeExist) {
        alert("This EAN code is already used for another voucher. Please use a different EAN code.");
        return;
    }

    const totalGvAmount = parseFloat(gvinvamount) + parseFloat(gvextamount);
    if (parseFloat(gvamount) !== totalGvAmount) {
        alert("GV Amount must be equal to the sum of GV Internal Amount and GV External Amount.");
        return;
    }
    
    const newVoucher = {
      eancode,
      productDescription: productInfo?.description || '',
      productName: productInfo?.item_name || '',
      gvamount,
      gvinvamount,
      gvextamount,
      capqty,
      gvrefno: gvrefno || '',
      item_id: productInfo?.id || '',
      gv_item_id:  productInfo?.item_id || '', 
      gvstatus: productInfo?.gvstatus || ''
    };

    setVouchers([...vouchers, newVoucher]);
    setEanCode('');
    setGvAmount('');
    setGvInvAmount('');
    setGvExtAmount('');
    setProductName('');
    setProductDescription('');
    setItemId('');
    setGVRefno('');
    setGvStatus('');
    
  };

  const removeVoucher = (index) => {
    setVouchers(vouchers.filter((_, i) => i !== index));
  };

  const submitGVMap = () => {

    const gvHeadersData = {
      gvheaders: vouchers.map(voucher => ({
        gvcaption: caption,
        validfrom: datefrom,
        validto: dateto,
        capqty: parseInt(voucher.capqty),
        item_id: voucher.item_id,
        voucher_type_id : voucherId,
        gvdetail: {
          gv_item: voucher.gv_item_id,
          gvamount: parseFloat(voucher.gvamount),
          gvintamount: parseFloat(voucher.gvinvamount),
          gvextamount: parseFloat(voucher.gvextamount),
          gvrefno: voucher.gvrefno || '', // Or any other reference you wish to use
          gvstat: voucher.gvstatus
        }
      }))
    };
    
    console.log(gvHeadersData);
    dispatch(createMappedGvs(gvHeadersData))
    .then(() => {
     
      resetForm(); // Reset form on success
    })
    .catch(error => {
      console.error("Failed to create GVs: ", error);
     
    });

  }

  const handleVoucher = (voucherrecord) => {

      setvoucherId(voucherrecord.id);

  }

  const handleCSVUpload = (e) => {

    if (!datefrom || !dateto) {
      alert('Please fill in both the "Valid From" and "Valid To" dates before proceeding.');
      return; // Exit the function if either date is missing
    }

    const file = e.target.files[0];
    if (!file) return;

    Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: async (result) => {
            const csvData = result.data;
            const validationErrors = []; 
            for (const csvVoucher of csvData) {
                try {
                    const productData = await dispatch(getProduct(csvVoucher.eancode, '2024-04-01', '2024-04-30'));
                    if (productData.type === PRODUCT_SEARCH_FAIL) {
                      errors.push(`Product not found for EAN: ${csvVoucher.eancode}`);
                      continue;
                    }
                    const totalAmount = parseFloat(csvVoucher.gvinvamount) + parseFloat(csvVoucher.gvextamount);

                    if (parseFloat(csvVoucher.gvamount) !== totalAmount) {
                      validationErrors.push(`Total amount mismatch for EAN: ${csvVoucher.eancode}`);
                      continue; 
                    }
                    const newVoucher = {
                        eancode: csvVoucher.eancode,
                        productName: productData.item_name, // Assuming this structure based on your previous code
                        productDescription: productData.description,
                        item_id : productData.id,
                        productid: productData.item_id,
                        gv_item_id: productData.item_id,
                        gvamount: csvVoucher.gvamount,
                        gvinvamount: csvVoucher.gvinvamount,
                        gvextamount: csvVoucher.gvextamount,
                        capqty: csvVoucher.capqty,
                        gvrefno: csvVoucher.gvrefno,
                        gvstatus: productData.gvstatus
                    };

                    setVouchers(vouchers => [...vouchers, newVoucher]);
                    setErrors(validationErrors); 
                } catch (error) {

                  console.error('Error fetching product:', error);
                  errors.push(`Error processing EAN: ${csvVoucher.eancode}`);
                }
            }

            if (errors.length > 0) {
              setModalErrors(errors);
              setShowModal(true);
            }
        },
    });
};

  useEffect(() => {
    if (vouchers) {

      console.log("these are vouchers here", vouchers)
    }
  }, [vouchers, dispatch]);


  const resetForm = () => {
    setEanCode('');
    setCaption('');
    setDateFrom('');
    setDateTo('');
    setGvAmount('');
    setGvInvAmount('');
    setGvExtAmount('');
    setCapQty('');
    setGVRefno('');
    setGvStatus('');
    setProductName('');
    setProductDescription('');
    setItemId('');
    setvoucherId('');
    setVouchers([]);
    
  };


  const ErrorModal = () => (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>CSV Upload Errors</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul>
          {modalErrors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <div>
      <h3>Create New Gift Voucher Promotion</h3>
      <ErrorModal /> 
      {errors.map((error, index) => (
        <Alert key={index} variant="danger">
          {error}
        </Alert>
      ))} 
      <hr/>
       <VouchersList onVoucherTypeChange={handleVoucher} />

      <Form>
        
      <hr/>
   
        <Row className="mb-3">
       
          <Col>
          <Form.Label>Upload CSV File</Form.Label>
            <Form.Group controlId="formFile" className="mb-3">
            
              <Form.Control type="file" accept=".csv" onChange={handleCSVUpload} />
            </Form.Group>
          </Col>

          <Col md={4}>
            <Button variant="success" onClick={submitGVMap} className="my-3">
              Submit GV Map
            </Button>
          </Col>

        </Row>
     
     
      <Row className="align-items-center mb-3">
          <Col>
            <Form.Group controlId="caption">
              <Form.Label>Promotion Title</Form.Label>
              <Form.Control
                type="text"
                name="caption"
                value={caption}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="validdatefrom">
              <Form.Label>Valid From</Form.Label>
              <Form.Control
                type="date"
                name="datefrom"
                value={datefrom}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="validdateto">
              <Form.Label>Valid To</Form.Label>
              <Form.Control
                type="date"
                name="dateto"
                value={dateto}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <hr/>
        <Row className="align-items-center">
          <Col md={4}>
            <Form.Group controlId="eancode">
              <Form.Label>EanCode</Form.Label>
              <Form.Control
                type="text"
                name="eancode"
                value={eancode}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                required
              />
            </Form.Group>
          </Col>
          {productInfo && (
            <>
              <Col md={4}>
                <Form.Group controlId="productName">
                  <Form.Label>Product Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={productDescription || ''}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="productDescription">
                  <Form.Label>Model</Form.Label>
                  <Form.Control
                    type="text"
                    value={productName || ''}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="gvAmount">
                  <Form.Label>GV Amount</Form.Label>
                  <Form.Control
                    type="number"
                    value={gvamount}
                    onChange={(e) => setGvAmount(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="gvInvAmount">
                  <Form.Label>GV Internal Amount</Form.Label>
                  <Form.Control
                    type="number"
                    value={gvinvamount}
                    onChange={(e) => setGvInvAmount(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="gvExtAmount">
                  <Form.Label>GV External Amount</Form.Label>
                  <Form.Control
                    type="number"
                    value={gvextamount}
                    onChange={(e) => setGvExtAmount(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group controlId="gvcapqty">
                  <Form.Label>Cap Qty</Form.Label>
                  <Form.Control
                    type="number"
                    value={capqty}
                    onChange={(e) => setCapQty(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group controlId="gvrefno">
                  <Form.Label>Refno</Form.Label>
                  <Form.Control
                    type="text"
                    value={gvrefno}
                    onChange={(e) => setGVRefno(e.target.value)}
                  />
                </Form.Group>
              </Col>


              <Col md={4}>
                <Form.Group controlId="gvstatus">
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    type="text"
                    value={gvstatus}
                    style={{ color: gvstatus !== 'ok' ? 'red' : 'inherit' }}
                    onChange={(e) => setGvStatus(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </>
          )}



        </Row>

        <hr/>
        <Button variant="primary" onClick={addVoucher} className="mt-3">
          Add
        </Button>
      </Form>
      <hr/>
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>EanCode</th>
            <th>Product Name</th>
            <th>Product Description</th>
            <th>GV Amount</th>
            <th>GV Internal Amount</th>
            <th>GV External Amount</th>
            <th>Cap Qty</th>
            <th>GV Refno</th>
            <th>Status</th>
            <th>Actions</th>

          </tr>
        </thead>
        <tbody>
          {vouchers.map((voucher, index) => (
            <tr key={index}>
              <td>{voucher.eancode}</td>
              <td>{voucher.productName}</td>
              <td>{voucher.productDescription}</td>
              <td>{voucher.gvamount}</td>
              <td>{voucher.gvinvamount}</td>
              <td>{voucher.gvextamount}</td>
              <td>{voucher.capqty}</td>
              <td>{voucher.gvrefno}</td>
              <td style={{ color: voucher.gvstatus !== 'ok' ? 'red' : 'inherit' }}>
                {voucher.gvstatus}
              </td>

              <td>
                <Button variant="danger" onClick={() => removeVoucher(index)}>Remove</Button>
              </td>

            
            </tr>

            
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default CreateGVMap;
