import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { gvredeemlistaction } from '../actions/VoucherAction';
import * as XLSX from 'xlsx';

function VouchersRedeemed() {
    const dispatch = useDispatch();
    const { redeemedvouchers } = useSelector((state) => state.gvredeemlist);
    const [filter, setFilter] = useState('');
    const [brandFilter, setBrandFilter] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    // Dispatch the action with updated dates when fromDate or toDate changes
    useEffect(() => {
        if (fromDate && toDate) {
            dispatch(gvredeemlistaction(fromDate, toDate));
        }
    }, [dispatch, fromDate, toDate]);

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const handleBrandFilterChange = (e) => {
        setBrandFilter(e.target.value);
    };

    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    const filteredVouchers = redeemedvouchers?.filter(voucher => {
        const validFrom = new Date(voucher.invoicedate);
        const fromDateCondition = fromDate ? new Date(fromDate) <= validFrom : true;
        const toDateCondition = toDate ? new Date(toDate) >= validFrom : true;
        const dateRangeCondition = fromDateCondition && toDateCondition;

        return (
            (voucher.itemname.toLowerCase().includes(filter.toLowerCase()) ||
            voucher.eancode.toLowerCase().includes(filter.toLowerCase())) &&
            voucher.brand.toLowerCase().includes(brandFilter.toLowerCase()) &&
            dateRangeCondition
        );
    });

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(filteredVouchers);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Vouchers");
        XLSX.writeFile(workbook, "RedeemedVouchers.xlsx");
    };

    return (
        <div>
            <h3>Redeemed Vouchers</h3>
            <Row>
                <Form.Label>Filter by Item Name, EAN Code, Brand, or Date Range</Form.Label>
                <Col md={3}>
                    <Form.Group controlId="formGridFilter">
                        <Form.Control
                            type="text"
                            placeholder="Enter item name or EAN code"
                            value={filter}
                            onChange={handleFilterChange}
                        />
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group controlId="formGridBrandFilter">
                        <Form.Control
                            type="text"
                            placeholder="Enter brand"
                            value={brandFilter}
                            onChange={handleBrandFilterChange}
                        />
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group controlId="formGridFromDate">
                        <Form.Control
                            type="date"
                            value={fromDate}
                            onChange={handleFromDateChange}
                        />
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group controlId="formGridToDate">
                        <Form.Control
                            type="date"
                            value={toDate}
                            onChange={handleToDateChange}
                        />
                    </Form.Group>
                </Col>
                <Col md={12} className="text-right">
                    <Button variant="success" onClick={exportToExcel}>Export Data</Button>
                </Col>
            </Row>
            <hr />
            {redeemedvouchers && (
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Invoice Date</th>
                            <th>Store Name</th>
                            <th>Invoice Number</th>
                            <th>Item Sys ID</th>
                            <th>Item ID</th>
                            <th>EAN Code</th>
                            <th>Item Name</th>
                            <th>Brand</th>
                            <th>Category</th>
                            <th>Amount</th>
                            <th>Qty</th>
                            <th>GV Value</th>
                            <th>Ext Value</th>
                            <th>Int Value</th>
                            <th>Parent ID</th>
                            <th>GV Ref</th>
                            <th>Promo Code</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredVouchers.map((gv, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{gv.invoicedate}</td>
                                <td>{gv.storename}</td>
                                <td>{gv.invoicenumber}</td>
                                <td>{gv.itemsysid}</td>
                                <td>{gv.itemid}</td>
                                <td>{gv.eancode}</td>
                                <td>{gv.itemname}</td>
                                <td>{gv.brand}</td>
                                <td>{gv.category}</td>
                                <td>{gv.amount}</td>
                                <td>{gv.qty}</td>
                                <td>{gv.price}</td>
                                <td>{gv.gvextvalue}</td>
                                <td>{gv.gvintvalue}</td>
                                <td>{gv.parentid}</td>
                                <td>{gv.gvref}</td>
                                <td>{gv.promocode}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </div>
    );
}

export default VouchersRedeemed;
