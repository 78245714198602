import React from 'react'
import { Row,Col } from 'react-bootstrap'
import VouchersList from '../components/VouchersList'

function HomeScreen() {

 
  return (
    <div>
            <h1>Recently Created Vouchers</h1>
          
    </div>
  )
}

export default HomeScreen
