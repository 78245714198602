import { 
    
    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_LIST_FAIL,

    PRODUCT_SEARCH_REQUEST,
    PRODUCT_SEARCH_SUCCESS,
    PRODUCT_SEARCH_FAIL,


} from '../constants/ProductConstant'


export const productSearchReducer = (state = {}, action) => {

    switch (action.type){

        case PRODUCT_SEARCH_REQUEST:
            return {...state, loading: true, productInfo: undefined};
        
        case PRODUCT_SEARCH_SUCCESS:
            return {loading: false, productInfo: action.payload }
        
        case PRODUCT_SEARCH_FAIL:
            return {loading: false, error: action.payload}
         
        
        default:
            return state


    }

}


export const productListReducer = (state = {products: []}, action) => {

    switch(action.type){

        case PRODUCT_LIST_REQUEST:
            return {loading: true, products: []}
        
        case PRODUCT_LIST_SUCCESS:
            return {loading:false, products: action.payload}
        
        case PRODUCT_LIST_FAIL:
            return {loading: false, error: action.payload}
        
        
        default:
            return state

    }


}
