import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

function ProtectedRoute({ children }) {

    const location = useLocation();
    const userInfoFromStorage = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : null;
    const isLoggedIn = userInfoFromStorage !== null;
  
    if (!isLoggedIn) {
      // Redirect them to the login page but save the current location they were trying to go to
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  
    return children;
 
  
}

export default ProtectedRoute
