import React from 'react'
import { Navbar, Nav, Container, Row } from 'react-bootstrap'
import { Link, Route, Routes, useNavigate, BrowserRouter as Router } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';


function Header({ isLoggedIn, onLogout }) {

  const navigate = useNavigate();
  const handleLogout = () => {
    onLogout();
    navigate('/login'); // Redirect to the login screen after logout
  };

  const userInfoFromStorage = localStorage.getItem("userInfo") ?
JSON.parse(localStorage.getItem("userInfo")) : null

const fullName =
    userInfoFromStorage &&
    `${userInfoFromStorage[0].first_name} ${userInfoFromStorage[0].last_name}`;
  return (
    <header>
      <Navbar expand="lg" bg='light' variant='light' className="bg-body-tertiary" collapseOnSelect>
      <Container>
        <Navbar.Brand><Link to="/" className="nav-link">VK Technologies</Link></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">

          <Link to="/mapgiftvoucher" className="nav-link">Map Gift Vouchers</Link>
          <Link to="/createstoregv" className="nav-link">Create Store Gift Voucher</Link>
          <Link to="/storegvlist" className="nav-link">Store Gift Voucher List</Link>
          <Link to="/mappedvoucherlist" className="nav-link">Vouchers Mapped</Link>
          <Link to="/vouchersredeemed" className="nav-link">Vouchers Redeemed</Link>
          
          <Nav className="ml-auto">
          {isLoggedIn ? (
              // If the user is logged in, show "Logoff"
            
              <Nav.Link onClick={handleLogout}>Logoff</Nav.Link>
              

            ) : (
              // If the user is not logged in, show "Login"
              <LinkContainer to="/login">
                <Nav.Link>Login</Nav.Link>
              </LinkContainer>

              
            )}
            </Nav>
            <Nav.Link>Logged In : {fullName}</Nav.Link>
           
           
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </header>
  )
}

export default Header
