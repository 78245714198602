export const VOUCHER_CREATE_REQUEST = 'VOUCHER_CREATE_REQUEST'
export const VOUCHER_CREATE_SUCCESS = 'VOUCHER_CREATE_SUCCESS'
export const VOUCHER_CREATE_FAIL = 'VOUCHER_CREATE_FAIL'

export const VOUCHER_CHECK_REQUEST = 'VOUCHER_CHECK_REQUEST'
export const VOUCHER_CHECK_SUCCESS = 'VOUCHER_CHECK_SUCCESS'
export const VOUCHER_CHECK_FAIL = 'VOUCHER_CHECK_FAIL'

export const VOUCHER_LIST_REQUEST = 'VOUCHER_CREATE_REQUEST'
export const VOUCHER_LIST_SUCCESS = 'VOUCHER_CREATE_SUCCESS'
export const VOUCHER_LIST_FAIL = 'VOUCHER_CREATE_FAIL'

export const STORE_GV_GENERATE_REQUEST = 'STORE_GV_GENERATE_REQUEST'
export const STORE_GV_GENERATE_SUCCESS = 'STORE_GV_GENERATE_SUCCESS'
export const STORE_GV_GENERATE_FAIL = 'STORE_GV_GENERATE_FAIL'

export const STORE_GV_CREATE_REQUEST = 'STORE_GV_CREATE_REQUEST'
export const STORE_GV_CREATE_SUCCESS = 'STORE_GV_CREATE_SUCCESS'
export const STORE_GV_CREATE_FAIL = 'STORE_GV_CREATE_FAIL'

export const CLEAR_GVS_LIST = 'CLEAR_GVS_LIST';

export const STORE_GV_LIST_REQUEST = 'STORE_GV_LIST_REQUEST'
export const STORE_GV_LIST_SUCCESS = 'STORE_GV_LIST_SUCCESS'
export const STORE_GV_LIST_FAIL = 'STORE_GV_LIST_FAIL'

export const GV_MAP_CREATE_REQUEST = 'GV_MAP_CREATE_REQUEST'
export const GV_MAP_CREATE_SUCCESS = 'GV_MAP_CREATE_SUCCESS'
export const GV_MAP_CREATE_FAIL = 'GV_MAP_CREATE_FAIL'

export const GV_MAPPED_LIST_REQUEST = 'GV_MAPPED_LIST_REQUEST'
export const GV_MAPPED_LIST_SUCCESS = 'GV_MAPPED_LIST_SUCCESS'
export const GV_MAPPED_LIST_FAIL = 'GV_MAPPED_LIST_FAIL'

export const GV_REDEEMED_LIST_REQUEST = 'GV_REDEEMED_LIST_REQUEST'
export const GV_REDEEMED_LIST_SUCCESS = 'GV_REDEEMED_LIST_SUCCESS'
export const GV_REDEEMED_LIST_FAIL = 'GV_REDEEMED_LIST_FAIL'

